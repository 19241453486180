// Deprecated: This file is deprecated. Please use 'Dialog' instead.
import { useCallback, useEffect, useRef } from "react"
import { createStateContext } from "react-use"
import { v4 as uuid } from "uuid"

type UUID = ReturnType<typeof uuid>
type Data = {
  title: string
  subtitle?: string
  description?: string | (() => string | undefined)
  jsx?: JSX.Element
}

type State = Data & {
  /**
   * The id is generated automatically.
   */
  id?: UUID
}

export const [useModalState, ModalProvider] = createStateContext<State[]>([])

export const useModal = (data?: Data) => {
  const [stack, setStack] = useModalState()

  const key = useRef<UUID | null>(null)

  const close = useCallback(() => {
    setStack((prev) => {
      const next = [...prev]
      next.pop()

      return next
    })
  }, [setStack])

  const open = useCallback(() => {
    if (!data) return

    setStack((prev) => {
      return [...prev, { ...data, id: key.current! }]
    })
  }, [data, setStack])

  const closeAll = useCallback(() => setStack([]), [setStack])

  useEffect(() => {
    key.current = uuid()
  }, [])

  return {
    stack,
    open,
    close,
    closeAll,
  }
}
