import {
  UseQueryOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query"
import { certificationProcessService } from "src/services"

export function useUnlinkImplementedSportProduct(options?: UseQueryOptions) {
  const queryClient = useQueryClient()

  async function mutateFn(data: { id: string; ispId: string }) {
    const response = await certificationProcessService.unlinkImplementedSportProduct(data.id, data.ispId) // prettier-ignore
    await queryClient.refetchQueries(["certification-processes", data.id])
    return response.data
  }

  return useMutation(mutateFn, {
    onSuccess: (...args) => {
      options?.onSuccess?.(args)
    },
  })
}
