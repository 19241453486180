import { ORGANIZATION_TYPES } from "@constants"
import { z } from "zod"

export enum CertificationProcessState {
  CREATED = "CREATED",
  WAITING_FOR_CERTIFICATION = "WAITING_FOR_CERTIFICATION",
  COMPLETED = "COMPLETED",
  CANCELED = "CANCELED",
}

export enum CertificationProcessReason {
  NEW = "NEW",
  RECERTIFICATION = "RECERTIFIFCATION", // note: there's a typo here, but renaming it would require a database migration
  RENOVATION = "RENOVATION",
  MIXED = "MIXED",
}
export const CertificationProcessReasonLabels = {
  [CertificationProcessReason.NEW]: "Nieuwbouw",
  [CertificationProcessReason.RECERTIFICATION]: "Hercertificering",
  [CertificationProcessReason.RENOVATION]: "Renovatie",
  [CertificationProcessReason.MIXED]: "Meerdere",
} as const
export const CertificationProcessReasonOptions = Object.entries(
  CertificationProcessReasonLabels,
).map(([value, label]) => ({ value, label }))

export interface DisciplineSportClubs {
  id?: number
  name: string
  type: ORGANIZATION_TYPES.SPORTCLUBS
  kvkNumber?: string | null
  kvkEstablishmentNumber?: string | null
  city?: string | null
  address?: string | null
}

export interface IspDiscipline {
  code: string
  state: string
  sportClubs: DisciplineSportClubs[]
}

export const implementedSportProductSchema = z.object({
  id: z.string().uuid(),
  accommodationId: z.string().uuid(),
  mainContractorId: z.number(),
  contractorId: z.number(),
  consultantId: z.number().optional(),
  isRetired: z.boolean(),
  sportProduct: z.object({
    id: z.string().uuid(),
    name: z.string(),
    sportProductType: z.object({
      id: z.string().uuid(),
      name: z.string(),
    }),
  }),
  mainContractor: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .optional(),
  contractor: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .optional(),
  consultant: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .optional(),
  certificationProcesses: z.array(
    z.object({
      id: z.string().uuid(),
      state: z.nativeEnum(CertificationProcessState),
    }),
  ),
  disciplines: z.array(
    z.object({
      code: z.string(),
      state: z.string(),
      sportClubs: z.array(
        z.object({
          id: z.number().optional(),
          name: z.string(),
          type: z.literal(ORGANIZATION_TYPES.SPORTCLUBS),
          kvkNumber: z.string().nullable().optional(),
          kvkEstablishmentNumber: z.string().nullable().optional(),
          city: z.string().nullable().optional(),
          address: z.string().nullable().optional(),
        }),
      ),
    }),
  ),
})

export type ImplementedSportProduct = z.infer<
  typeof implementedSportProductSchema
>
export type ImplementedSportProductWithDetails = ImplementedSportProduct & {
  createdAt: string
  updatedAt: string
  retiredAt: string | null
  replacedById: string | null
  certificationInstituteId: string | null
  sportProduct: {
    id: string
    name: string
    description: string | null
    updatedAt: string
    location: string
    typeId: string
    subtypeId: string
    productStatusId: string
    systemStatusId: string
    sustainabilityLabelId: string
    isActive: boolean
    manufacturerId: number
    testInstituteId: number
  }
}

export const implementedSportProductCreateSchema = z.object({
  sportProductId: z.string().uuid(),
  mainContractorId: z.number(),
  contractorId: z.number(),
  consultantId: z.number().optional(),
})

export const implementedSportProductCreateManySchema =
  implementedSportProductCreateSchema.array()

export type ImplementedSportProductCreate = z.infer<
  typeof implementedSportProductCreateSchema
>

export type ImplementedSportProductCreateMany = z.infer<
  typeof implementedSportProductCreateManySchema
>

export const implementedSportProductUpdateSchema = z.object({
  id: z.string().uuid(),
  sportProductId: z.string().uuid(),
  mainContractorId: z.number(),
  contractorId: z.number(),
  consultantId: z.number().optional(),
})

export type ImplementedSportProductUpdate = z.infer<
  typeof implementedSportProductUpdateSchema
>

export const implementedSportProductUpdateMany =
  implementedSportProductUpdateSchema.array()

export type ImplementedSportProductUpdateMany = z.infer<
  typeof implementedSportProductUpdateMany
>
