import {
  UseQueryOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query"
import {
  ImplementedSportProduct,
  certificationProcessService,
} from "src/services"
import { V2CertificationProcessLinkIsp } from "src/utils/v2"

export function useLinkImplementedSportProduct(options?: UseQueryOptions) {
  const queryClient = useQueryClient()

  async function mutateFn(data: V2CertificationProcessLinkIsp) {
    const response = await certificationProcessService.linkImplementedSportProduct(data.id, data) // prettier-ignore
    await queryClient.refetchQueries(["certification-processes", data.id])
    return response.data
  }

  return useMutation(mutateFn, {
    onSuccess: (data: ImplementedSportProduct[]) => {
      if (options?.onSuccess) {
        options?.onSuccess(data)
      }
    },
  })
}
