import { UseQueryOptions, useMutation } from "@tanstack/react-query"
import { disciplinesService } from "src/services"
import { V2ProjectIspDisciplines } from "src/utils/v2"

export function useManageDisciplines(cpId: string, options?: UseQueryOptions) {
  async function mutateFn(data: V2ProjectIspDisciplines) {
    const { ispDisciplines } = data

    for (const ispDiscipline of ispDisciplines) {
      const { ispId, disciplines } = ispDiscipline

      await disciplinesService.manageImplementedSportProductDisciplines(
        cpId,
        ispId,
        { disciplines },
      )
    }
  }

  return useMutation(mutateFn, {
    onSuccess: () => {
      if (options?.onSuccess) {
        options.onSuccess({})
      }
    },
  })
}
