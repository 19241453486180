import { styled } from "src/stitches.config"

// export const FormRow = styled("div", {
//   "@lgUp": {
//     display: "grid",
//     gridColumnGap: "$10",
//     gridTemplateColumns: "1fr 1fr",
//   },
// })

export const FormItem = styled("div", {
  "&:not(:last-child)": {
    marginBottom: "$20",
  },

  variants: {
    horizontal: {
      true: {
        "@mdUp": {
          display: "flex",

          "& > *:first-child": {
            flex: "0 0 180px",
            marginTop: "$5",
          },

          "& > *:last-child": {
            flex: "1 1",
          },
        },
      },
    },
    horizontalMinLabelWidth: {
      true: {
        "@mdUp": {
          display: "flex",

          "& > *:first-child": {
            flex: "0 0 max-content",
            marginTop: "$5",
            marginRight: "1.5rem",
          },

          "& > *:last-child": {
            flex: "1 1",
          },
        },
      },
    },
    noMargin: {
      true: {
        "&:not(:last-child)": {
          marginBottom: "0",
        },
      },
    },
  },
})
