import { UseQueryResult, useQuery } from "@tanstack/react-query"
import { implementedSportProductService } from "src/services"
import { ImplementedSportProduct } from "src/services/v2/implemented-sport-product"

export function useImplementedSportProductsByCertificationProcess(
  id: string,
): UseQueryResult<ImplementedSportProduct[]> {
  const enabled = Boolean(id)

  const queryFn = () =>
    implementedSportProductService.getAllByCertificationProcessId(id)

  const queryResult = useQuery(
    ["certification-process", id, "implemented-sport-products"],
    queryFn,
    {
      enabled,
    },
  )

  // Override isLoading when the query is disabled
  const isLoading = enabled ? queryResult.isLoading : false

  return {
    ...queryResult,
    isLoading,
  } as UseQueryResult<ImplementedSportProduct[]>
}
