import type * as Stitches from "@stitches/react"
import React, { useCallback, useMemo, useState } from "react"
import { useController, useFormContext } from "react-hook-form"
import { useIntl } from "react-intl"
import TextareaAutosize from "react-textarea-autosize"
import { styled } from "src/stitches.config"

interface Props {
  helpText?: string
  displayAs?: React.FC
  trimLineBreaks?: boolean
  italic?: boolean
  name: string
  label?: string
  placeholder?: string
  defaultValue?: string
  bigPadding?: boolean
  isDisabled?: boolean
  inputRef?: React.Ref<HTMLTextAreaElement>
  autoFocus?: boolean
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement>
  maxLength?: number
  showMaxLengthCounter?: boolean
  type?: "baseText" | "text" | "number" | "email"
  css?: Stitches.CSS
  onDarkBackground?: boolean
}

const InputWrapper = styled("div", {
  position: "relative",

  "& > *:not(:last-child)": {
    marginBottom: "$10",
  },
})

export const InputError = styled("div", {
  color: "$red",
})

const InputControlWrapper = styled("span", {
  display: "flex",
  flexDirection: "column",
  margin: "0",

  textarea: {
    minHeight: "100%",
    height: "44px !important",
    width: "100%",
    margin: "0",
    backgroundColor: "$grayLight",
    borderRadius: "4px",
    borderTopStyle: "hidden",
    borderRightStyle: "hidden",
    borderLeftStyle: "outset",
    borderBottomStyle: "hidden",
    borderWidth: "2px",
    resize: "none",
  },

  input: {
    margin: "0",
    backgroundColor: "$grayLight",
    borderRadius: "4px",
    borderTopStyle: "hidden",
    borderRightStyle: "hidden",
    borderLeftStyle: "outset",
    borderBottomStyle: "hidden",
    borderWidth: "2px",
    outline: "none",
    borderColor: "transparent",

    "&:active, &:focus": {
      borderColor: "#EB5E00",
    },
  },

  compoundVariants: [
    {
      displayInline: true,
      bigPadding: true,
      css: {
        "textarea, input": {
          padding: "$20",
        },
      },
    },
  ],

  variants: {
    bigPadding: {
      true: {},
    },
    italic: {
      true: {
        fontStyle: "italic",
      },
    },
    displayInline: {
      true: {
        "textarea, input": {
          appearance: "none",
          padding: "$10",
          margin: "0",
          font: "inherit",
          color: "inherit",
          borderColor: "$grayLight",
          outline: "none",
        },
      },
    },
    isDisabled: {
      true: {
        "textarea, input": {
          color: "$gray",
          background: "$white",
          border: "1px solid $grayLight",
        },
      },
    },
    onDarkBackground: {
      true: {
        "textarea, input": {
          color: "$gray",
          background: "$white",
          border: "1px solid $grayLight",
        },
      },
    },
  },
})

const TextInput = styled("input", {
  padding: "$10",
})

const TextareaAutosizeStyled = styled(TextareaAutosize, {
  "&:active, &:focus": {
    borderColor: "#EB5E00",
  },

  variants: {
    hasHelp: {
      true: {
        paddingBottom: "44px !important",
      },
    },
  },
})

const EditingHelper = styled("span", {
  display: "block",
  backgroundColor: "#CBD4C2",
  color: "#4A4A4A",
  height: 24,
  borderRadius: 4,
  marginTop: "-34px",
  marginBottom: "$10",
  marginLeft: "$10",
  padding: "0 $10",
  alignSelf: "start",
  fontFamily: "$grotesk",
  fontSize: ".75rem",
  opacity: 0,
  transition: "opacity 80ms linear",

  variants: {
    visible: {
      true: {
        opacity: 1,
      },
    },
  },
})

export const FormInput = ({
  helpText,
  displayAs,
  trimLineBreaks,
  italic,
  bigPadding,
  name,
  defaultValue,
  label,
  placeholder,
  autoFocus = false,
  inputRef,
  onBlur,
  isDisabled,
  maxLength,
  showMaxLengthCounter = false,
  type = "text",
  onDarkBackground,
  ...rest
}: Props) => {
  const { formatMessage } = useIntl()
  const [helpTextVisible, setHelpTextVisible] = useState(false)
  const { setValue, watch } = useFormContext()
  const { field, fieldState } = useController({
    name: name,
    defaultValue: defaultValue,
    shouldUnregister: true,
  })

  const value = watch(name)

  const handleTextChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      let { value, name } = event.target

      if (trimLineBreaks) {
        value = value.replace(/(\r\n|\n|\r)/gm, "")
      }

      setValue(name, value, { shouldValidate: true, shouldDirty: true })
    },
    [],
  )
  const handleNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value, 10) // Convert the input value to a number
    setValue(name, value) // Set the converted value using setValue
  }

  const handleFocus = useCallback(() => {
    setHelpTextVisible(true)
  }, [])

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLTextAreaElement>) => {
      setHelpTextVisible(false)
      onBlur && onBlur(event)
    },
    [onBlur],
  )

  const isHelpTextVisible = useMemo(() => {
    if (showMaxLengthCounter) {
      return true
    }

    return helpTextVisible && !!helpText?.length
  }, [helpTextVisible, showMaxLengthCounter])

  return (
    <InputWrapper {...rest}>
      {label && <label htmlFor={name}>{label}</label>}

      <InputControlWrapper
        italic={italic}
        displayInline={!!displayAs}
        bigPadding={bigPadding}
        isDisabled={isDisabled}
        onDarkBackground={onDarkBackground}
      >
        {type === "baseText" && (
          <TextInput
            {...field}
            type="text"
            disabled={isDisabled}
            autoFocus={autoFocus}
            placeholder={
              typeof placeholder === "string"
                ? placeholder
                : formatMessage({ id: `form.${name}.placeholder` })
            }
            onChange={handleTextChange}
          />
        )}

        {type === "text" && (
          <TextareaAutosizeStyled
            {...field}
            placeholder={
              typeof placeholder === "string"
                ? placeholder
                : formatMessage({ id: `form.${name}.placeholder` })
            }
            defaultValue={defaultValue}
            onChange={handleTextChange}
            ref={inputRef}
            autoFocus={autoFocus}
            onFocus={handleFocus}
            onBlur={handleBlur}
            hasHelp={isHelpTextVisible}
            disabled={isDisabled}
            maxLength={maxLength}
          />
        )}

        {type === "email" && (
          <input
            {...field}
            type="email"
            disabled={isDisabled}
            maxLength={maxLength}
            placeholder={
              typeof placeholder === "string"
                ? placeholder
                : formatMessage({ id: `form.${name}.placeholder` })
            }
            onChange={handleTextChange}
          />
        )}

        {type === "number" && (
          <input
            {...field}
            type="number"
            disabled={isDisabled}
            placeholder={
              typeof placeholder === "string"
                ? placeholder
                : formatMessage({ id: `form.${name}.placeholder` })
            }
            onChange={handleNumberChange}
          />
        )}

        {isHelpTextVisible && (
          <EditingHelper visible={isHelpTextVisible} data-cy="length-counter">
            {showMaxLengthCounter && maxLength
              ? `${value?.length || 0} / ${maxLength} tekens`
              : helpText}
          </EditingHelper>
        )}
      </InputControlWrapper>

      {fieldState.error && <InputError>{fieldState.error.message}</InputError>}
    </InputWrapper>
  )
}
