import { IconWrapper } from "@components"
import { useIntl } from "react-intl"
import { P } from "src/components/typography"
import {
  CertificationProcessState,
  ImplementedSportProduct,
  useCertificationProcessCertifyData,
} from "src/services"
import { useInspectionReport } from "src/services/v2/inspection-report"
import { styled } from "src/stitches.config"
import { useHasPermissions } from "src/utils/has-permissions"

import { ProjectDetailsList } from "./fragments/organizations-list"

interface Props {
  cpId: string
  isp: ImplementedSportProduct
  actions?: React.ReactElement | null
}

const V2ImplementedSportProductCardWrapper = styled("div", {
  display: "flex",
  padding: "$20",
  backgroundColor: "$grayLight",
  borderRadius: "4px",

  variants: {
    isRetired: {
      true: {
        opacity: 0.5,
      },
    },
  },
})

const V2ImplementedSportProductBody = styled("div", {
  width: "100%",
})

const V2ImplementedSportProductHeader = styled("div", {
  display: "flex",
  width: "100%",
  borderBottom: "1px solid #D5D5D5",
  marginBottom: "$20",
})

const V2ImplementedSportProductActions = styled("div", {
  marginBlock: "$20",
})

const SportProductType = styled("div", {
  fontFamily: "$nova",
  fontWeight: "$bold",
  fontSize: "$16",
  width: "100%",
  paddingBottom: "$10",
})

const PDFIconWrapper = styled("div", {
  position: "relative",
  display: "flex",
  alignItems: "center",
  borderRadius: "6px",
  height: "$30",
  width: "$30",
  minWidth: "$30",
  backgroundColor: "$bodyGreen",
  boxShadow: "$default",
  justifyContent: "center",
})

const ReportsLinkText = styled("span", {
  whiteSpace: "nowrap",
})

const ReportsLinkWrapper = styled("a", {
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: "$10",
  textDecoration: "none",
})

export const V2ImplementedSportProductCard = ({
  cpId,
  isp,
  actions,
}: Props) => {
  const { formatMessage } = useIntl()
  const hasGetReportPermission = useHasPermissions(["InspectionReportGet"])

  const { data: certificationProcess } = useCertificationProcessCertifyData(
    cpId ?? "",
  )

  const { data: inspectionReport } = useInspectionReport(cpId, isp.id)

  const certificationProcessImplementedSportProduct =
    certificationProcess?.implementedSportProducts.find(
      (cpIsp) => isp.id === cpIsp.id,
    )

  const inspectionReportUrl =
    inspectionReport?.url ||
    certificationProcessImplementedSportProduct?.inspectionReportUrl

  const inspectionToken = certificationProcess?.inspectionToken || ""

  const canViewInspectionReport =
    hasGetReportPermission ||
    certificationProcess?.state !== CertificationProcessState.CREATED

  return (
    <V2ImplementedSportProductCardWrapper isRetired={isp.isRetired}>
      <V2ImplementedSportProductBody>
        <V2ImplementedSportProductHeader>
          <SportProductType>
            {formatMessage({
              id: `sportsProductType.${isp.sportProduct.sportProductType.name}`,
            })}
          </SportProductType>

          {inspectionReportUrl &&
            inspectionToken &&
            canViewInspectionReport && (
              <P css={{ marginBottom: "$20" }}>
                <ReportsLinkWrapper
                  data-cy="download-report-link"
                  href={inspectionReportUrl + "?" + inspectionToken}
                  target="_blank"
                  className="underlined"
                >
                  <ReportsLinkText>Bekijk rapport</ReportsLinkText>
                  <PDFIconWrapper>
                    <IconWrapper name="PDFIcon" />
                  </PDFIconWrapper>
                </ReportsLinkWrapper>
              </P>
            )}
        </V2ImplementedSportProductHeader>

        <ProjectDetailsList isp={isp} />

        <V2ImplementedSportProductActions>
          {!isp.isRetired && actions}
        </V2ImplementedSportProductActions>
      </V2ImplementedSportProductBody>
    </V2ImplementedSportProductCardWrapper>
  )
}
