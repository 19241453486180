import {
  UseQueryOptions,
  UseQueryResult,
  useQuery,
} from "@tanstack/react-query"
import { v2Routes } from "src/services"
import { certificationProcessService } from "src/services"
import { useHasPermissions } from "src/utils/has-permissions"

import { CertificationProcess } from "../types"

export function useCertificationProcess(
  id: string,
  options?: UseQueryOptions,
): UseQueryResult<CertificationProcess> {
  const hasId = Boolean(id)

  const enabled = useHasPermissions(
    v2Routes.certificationProcess.detail.permissions,
  )

  const queryFn = () => certificationProcessService.getById(id)
  const queryResult = useQuery(["certification-processes", id], queryFn, {
    enabled: enabled && hasId,
    initialData: options?.initialData,
  })

  const isLoading = enabled && hasId ? queryResult.isLoading : false

  return {
    ...queryResult,
    isLoading,
  } as UseQueryResult<CertificationProcess>
}
