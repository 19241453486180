import Link from "next/link"
import { ImplementedSportProduct } from "src/services"
import { styled } from "src/stitches.config"

const ProjectDetailsListWrapper = styled("div", {
  display: "grid",
  gridTemplateColumns: "14rem 1fr",
  gap: "$10",
  fontSize: "$body",
})

const ProjectDetailsListItemLabel = styled("div", {
  fontWeight: "$bold",
})

const ProjectDetailsListItem = styled("div", {
  display: "inline-block",

  "&:not(:last-child)::after": {
    content: ", ",
    whiteSpace: "pre",
  },
})

interface Props {
  isp: ImplementedSportProduct
}

export function ProjectDetailsList({ isp }: Props) {
  const { mainContractor, contractor, consultant, sportProduct } = isp

  return (
    <ProjectDetailsListWrapper>
      {sportProduct && (
        <>
          <ProjectDetailsListItemLabel>
            Sportproduct
          </ProjectDetailsListItemLabel>
          <ProjectDetailsListItem>
            <Link href={`/sportproducten/${sportProduct.id}`}>
              {sportProduct.name}
            </Link>
          </ProjectDetailsListItem>
        </>
      )}
      {mainContractor && (
        <>
          <ProjectDetailsListItemLabel>
            Hoofdaannemer
          </ProjectDetailsListItemLabel>

          <ProjectDetailsListItem>
            <Link href={`/organisaties/${mainContractor.id}`}>
              {mainContractor.name}
            </Link>
          </ProjectDetailsListItem>
        </>
      )}
      {contractor && (
        <>
          <ProjectDetailsListItemLabel>
            Aannemer/leverancier
          </ProjectDetailsListItemLabel>

          <ProjectDetailsListItem>
            <Link href={`/organisaties/${contractor.id}`}>
              {contractor.name}
            </Link>
          </ProjectDetailsListItem>
        </>
      )}
    </ProjectDetailsListWrapper>
  )
}
