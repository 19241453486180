import Link from "next/link"
import { Fragment } from "react"
import { Button, ContentBox } from "src/components"
import { styled } from "src/stitches.config"

import { AccommodationButtons } from "../accommodation-buttons"

type DetailsBoxDataItem = {
  label: string
  value: string | (string | undefined)[]
  href?: string
}

export type DetailsBoxData = Array<DetailsBoxDataItem> | null

interface Props {
  data: DetailsBoxData
  showEditButton?: boolean
  editHandler?: () => void
}

const ProjectDetailsRow = styled("div", {
  display: "flex",

  "&:not(:last-child)": {
    marginBottom: "$20",
  },
})

const ProjectDetailsRowLabel = styled("div", {
  flex: "0 0 200px",
  fontFamily: "$nova",
  fontWeight: "$bold",
})

function renderValue(value: DetailsBoxDataItem["value"]) {
  // Render an array of values with each value on a new line
  if (Array.isArray(value)) {
    return value.filter(Boolean).map((item, index) => (
      <Fragment key={index}>
        {item}
        <br />
      </Fragment>
    ))
  }

  // If it's not an array, just render it as is
  return value
}

export const DetailsBox = ({
  data,
  showEditButton = false,
  editHandler,
}: Props) => {
  if (!data) return null

  return (
    <ContentBox
      css={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        gap: "20px",
      }}
    >
      <div style={{ width: "100%" }}>
        {data.map((item) => (
          <ProjectDetailsRow key={item.label}>
            <ProjectDetailsRowLabel>{item.label}</ProjectDetailsRowLabel>
            <div>
              {item.href ? (
                <Link href={item.href} className="underlined">
                  {renderValue(item.value)}
                </Link>
              ) : (
                renderValue(item.value)
              )}
            </div>
          </ProjectDetailsRow>
        ))}
      </div>

      {showEditButton && (
        <Button
          variant="secondary"
          icon="edit"
          as="div"
          size="small"
          data-testid="edit-button"
          onClick={editHandler}
        />
      )}
    </ContentBox>
  )
}
