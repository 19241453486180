import { MappedSport } from "src/services"
import { z } from "zod"

import {
  CertificationProcessReason,
  CertificationProcessState,
  implementedSportProductCreateSchema,
} from "../implemented-sport-product"
import { Organization } from "../organizations"

export interface CertificationProcess {
  id: string
  accommodationId: string
  dispensation?: string
  isReadyForCertification: boolean
  state: CertificationProcessState
  reason: CertificationProcessReason
  code: string
  certificationInstitute?: {
    id: number
    name: string
  }
  contact?: {
    name?: string
    organization?: string
    email?: string
    phone?: string
  }
  createdAt: Date
  updatedAt: Date
}

export type CertificationProcessData = {
  id: string
  code: string
  state: CertificationProcessState
  reason: CertificationProcessReason
  dispensation: string | null
  createdAt: string
  updatedAt: string
  accommodationId: string
  contactId: number
  contact: {
    name?: string
    email: string
  }
  certificationInstituteId: number
  originalProjectId: string | null
}

export const certificationProcessCreateSchema = z.object({
  certificationInstituteId: z.number(),
  dispensation: z.string().max(255).optional(),
  reason: z.nativeEnum(CertificationProcessReason),
  contact: z.object({
    name: z.string().optional(),
    organization: z.string().optional(),
    email: z
      .string()
      .min(1, { message: "Dit is een verplicht veld" })
      .email("Ongeldig e-mailadres"),
    phone: z.string().optional(),
  }),
})

export const certificationProcessUpdateSchema =
  certificationProcessCreateSchema.extend({
    id: z.string().uuid(),
  })

export const combinedProjectCreateSchema = z.object({
  accommodationId: z.string().uuid(),
  certificationInstituteId: z.number(),
  reason: z.nativeEnum(CertificationProcessReason),
  consultantId: z.number().optional(),
  contact: z.object({
    name: z.string().optional(),
    email: z
      .string()
      .min(1, { message: "Dit is een verplicht veld" })
      .email("Ongeldig e-mailadres"),
  }),
  sportProducts: z.array(
    implementedSportProductCreateSchema.extend({
      id: z.string().uuid().optional(),
    }),
  ),
})

export type CombinedProjectCreate = z.infer<typeof combinedProjectCreateSchema>

export type CertificationProcessCreate = z.infer<
  typeof certificationProcessCreateSchema
>

export type CertificationProcessUpdate = z.infer<
  typeof certificationProcessUpdateSchema
>

export type CertificationProcessCertify = {
  id: string
}

export interface CertifyData {
  id: string
  state: CertificationProcessState
  dispensation?: string
  accommodation: {
    id: string
    owner: Organization
    city: string
    code: string
    latitude: string
    longitude: string
    name: string
  }
  implementedSportProducts: {
    id: string
    sportProductName: string
    inspectionReportUrl: string | null
    sports: MappedSport[]
  }[]
  inspectionToken: string
}

export type CertificationProcessListItem = {
  id: string
  code: string
  state: string
  updatedAt: Date
  certificationInstituteName: string | null
  disciplineNames: string
  sportClubNames: string
  reason: CertificationProcessReason
}

export type CertificationProcessListItems = Array<CertificationProcessListItem>
