import {
  UseQueryOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query"

import { certificationProcessService } from "../.."
import { CertificationProcessUpdate } from "../types"

export function useUpdateCertificationProcess(options?: UseQueryOptions) {
  const queryClient = useQueryClient()

  async function mutateFn(data?: CertificationProcessUpdate) {
    if (!data) {
      console.log("No project data provided")
      return
    }

    const result = await certificationProcessService.update(data.id, data)
    await queryClient.refetchQueries(["certification-processes", data.id])
    return result
  }

  return useMutation(mutateFn, {
    onSuccess: (...args) => {
      options?.onSuccess?.(args)
    },
  })
}
