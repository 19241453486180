import { IIcon } from "@types"
import { ReactElement, ReactSVG } from "react"

export const PDFIcon = ({
  fill = "#EB5E00",
  height = "12",
  width = "13",
}: IIcon): ReactElement<ReactSVG> => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask id="path-1-inside-1_6642_988" fill="white">
      <path d="M8.1716 0L2 0L2 12H11L11 2.8284L8.1716 0ZM10.3333 11.3333H2.66667L2.66667 0.666667L7.2288 0.666667V3.7712H10.3333V11.3333ZM7.89547 3.10453V0.666667L10.3333 3.10453H7.89547Z" />
    </mask>
    <path
      d="M8.1716 0L2 0L2 12H11L11 2.8284L8.1716 0ZM10.3333 11.3333H2.66667L2.66667 0.666667L7.2288 0.666667V3.7712H10.3333V11.3333ZM7.89547 3.10453V0.666667L10.3333 3.10453H7.89547Z"
      fill={fill}
    />
    <path
      d="M8.1716 0L8.87871 -0.707107L8.58581 -1L8.1716 -1V0ZM2 0V-1H1V0L2 0ZM2 12H1L1 13H2L2 12ZM11 12V13H12V12H11ZM11 2.8284H12V2.41419L11.7071 2.12129L11 2.8284ZM10.3333 11.3333L10.3333 12.3333H11.3333L11.3333 11.3333H10.3333ZM2.66667 11.3333H1.66667V12.3333H2.66667V11.3333ZM2.66667 0.666667V-0.333333L1.66667 -0.333333V0.666667H2.66667ZM7.2288 0.666667H8.2288V-0.333333L7.2288 -0.333333V0.666667ZM7.2288 3.7712H6.2288V4.7712H7.2288V3.7712ZM10.3333 3.7712L11.3333 3.7712V2.7712L10.3333 2.7712V3.7712ZM7.89547 3.10453H6.89547V4.10453L7.89547 4.10453V3.10453ZM7.89547 0.666667L8.60257 -0.0404401L6.89547 -1.74755V0.666667L7.89547 0.666667ZM10.3333 3.10453V4.10453H12.7475L11.0404 2.39743L10.3333 3.10453ZM8.1716 -1L2 -1V1L8.1716 1V-1ZM1 0L1 12H3L3 0L1 0ZM2 13H11L11 11H2L2 13ZM12 12L12 2.8284L10 2.8284V12H12ZM11.7071 2.12129L8.87871 -0.707107L7.46449 0.707107L10.2929 3.53551L11.7071 2.12129ZM10.3333 10.3333H2.66667V12.3333H10.3333L10.3333 10.3333ZM3.66667 11.3333L3.66667 0.666667L1.66667 0.666667L1.66667 11.3333H3.66667ZM2.66667 1.66667L7.2288 1.66667V-0.333333L2.66667 -0.333333V1.66667ZM6.2288 0.666667V3.7712H8.2288V0.666667L6.2288 0.666667ZM7.2288 4.7712L10.3333 4.7712V2.7712H7.2288V4.7712ZM9.33333 3.7712V11.3333H11.3333V3.7712L9.33333 3.7712ZM8.89547 3.10453V0.666667L6.89547 0.666667V3.10453H8.89547ZM7.18836 1.37377L9.62623 3.81164L11.0404 2.39743L8.60257 -0.0404401L7.18836 1.37377ZM10.3333 2.10453H7.89547V4.10453H10.3333V2.10453Z"
      fill="white"
      mask="url(#path-1-inside-1_6642_988)"
    />
  </svg>
)
