import { useMemo } from "react"
import { styled } from "src/stitches.config"
import { getGoogleMapsLink } from "src/utils/get-google-maps-link"

interface Props {
  latitude: string
  longitude: string
  altText?: string
}

const MapWrapper = styled("div", {
  position: "relative",
  width: "100%",
  paddingBottom: "50%",
  cursor: "pointer",

  img: {
    position: "absolute",
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
})

export const StaticLocationMap = ({ latitude, longitude, altText }: Props) => {
  const mapImgSrc = useMemo(() => {
    return `/api/accommodation/image/${latitude},${longitude}`
  }, [latitude, longitude])

  const mapLink = useMemo(() => {
    return getGoogleMapsLink(latitude, longitude)
  }, [latitude, longitude])

  const handleMapClick = () => {
    if (mapLink) {
      window.open(mapLink, "_blank")
    }
  }

  return (
    latitude &&
    longitude && (
      <MapWrapper>
        <img
          src={mapImgSrc}
          alt={altText ?? "Location map"}
          onClick={handleMapClick}
        />
      </MapWrapper>
    )
  )
}
