import { V2CertificationProcessLinkIsp } from "src/utils/v2"

import { V2Api } from "../api.v2"
import {
  CertificationProcessCreate,
  CertificationProcessData,
  CertificationProcessListItems,
  CertificationProcessUpdate,
  CertifyData,
} from "./types"

export class CertificationProcessService extends V2Api {
  public async create(
    accId: string,
    data: CertificationProcessCreate,
  ): Promise<CertificationProcessData> {
    return super.post(`/accommodation/${accId}/certification-process`, { data })
  }

  public async update(id: string, data: CertificationProcessUpdate) {
    return super.put(`/certification-process/${id}`, { data })
  }

  public async delete(id: string) {
    return super.delete(`/certification-process/${id}`)
  }

  public async getById(id: string) {
    return super.get(`/certification-process/${id}`)
  }

  public async getAll(): Promise<CertificationProcessListItems> {
    return super.get(`/certification-processes`)
  }

  public async getByIdToCertify(id: string): Promise<CertifyData> {
    return super.get(`/certification-process/${id}/certify-data`)
  }

  public async getAllByAccommodationId(accommodationId: string) {
    return super.get(
      `/accommodation/${accommodationId}/certification-processes`,
    )
  }

  public async linkImplementedSportProduct(
    id: string,
    certificationProcessLinkIsp: V2CertificationProcessLinkIsp,
  ) {
    const { implementedSportProductIds } = certificationProcessLinkIsp

    return super.post(
      `/certification-process/${id}/implemented-sport-products`,
      {
        data: {
          implementedSportProductIds,
        },
      },
    )
  }

  public async unlinkImplementedSportProduct(id: string, ispId: string) {
    return super.delete(
      `/certification-process/${id}/implemented-sport-product/${ispId}`,
    )
  }

  public async markAsWaitingForCertification(id: string) {
    return super.put(
      `certification-process/${id}/mark-as-waiting-for-certification`,
    )
  }

  public async certifyImmediately(id: string) {
    return super.put(`/certification-process/${id}/certify-immediately`)
  }
}
