import {
  UseQueryOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query"
import { useRouter } from "next/router"
import { toast } from "react-hot-toast"
import { accommodationService, v2Routes } from "src/services"
import { Accommodation } from "src/services/v2/accommodation"
import { V2CreateAccommodation, V2CreateAccommodationArray } from "src/utils/v2"

export function useCreateAccommodation(options?: UseQueryOptions) {
  const router = useRouter()
  const queryClient = useQueryClient()

  async function mutationFn(data: V2CreateAccommodation) {
    return accommodationService.create(data)
  }

  return useMutation(mutationFn, {
    onSuccess: async (data: Accommodation) => {
      toast.success("Accommodatie is aangemaakt")

      const { source } = v2Routes.accommodations.detail
      await router.push(source.replace(":id", data.id))
      queryClient.invalidateQueries(["accommodation", data.id])
      queryClient.invalidateQueries(["accommodations"])
      if (options?.onSuccess) {
        options?.onSuccess(data)
      }
    },
    onError: (error) => {
      toast.error(
        `Aanmaken accommodatie is niet gelukt. Probeer het nog een keer of neem contact met ons op (${error}).`,
      )
    },
  })
}

export function useCreateAccommodations(options?: UseQueryOptions) {
  const router = useRouter()
  const queryClient = useQueryClient()

  async function mutationFn(data: V2CreateAccommodationArray) {
    const promises = data.accommodations.map((accommodation) =>
      accommodationService.create(accommodation),
    )

    return Promise.all(promises)
  }

  return useMutation(mutationFn, {
    onSuccess: async (data: Accommodation[]) => {
      toast.success("Accommodaties zijn aangemaakt")

      const { source } = v2Routes.accommodations.all
      await router.push(source)

      data.forEach((accommodation) => {
        queryClient.invalidateQueries(["accommodation", accommodation.id])
      })
      queryClient.invalidateQueries(["accommodations"])

      if (options?.onSuccess) {
        options?.onSuccess(data)
      }
    },
    onError: (error) => {
      toast.error(
        `Aanmaken accommodaties is niet gelukt. Probeer het nog een keer of neem contact met ons op (${error}).`,
      )
    },
  })
}
