import { Button, icons } from "src/components"
import { styled } from "src/stitches.config"

interface Props {
  disabled?: boolean
  isLoading?: boolean
  text?: string
  description?: string
  type?: "button" | "submit"
  icon?: keyof typeof icons | JSX.Element
}

const ProceedButtonWrapper = styled("div", {
  position: "relative",
  marginTop: "40px",
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  gap: "10px",
})

const ProceedDescription = styled("div", {
  color: "$gray",
  fontSize: "$body",
})

export const ModalProceedButton = ({
  disabled = false,
  isLoading = false,
  text = "Voeg toe",
  description,
  type = "submit",
  icon,
}: Props) => {
  return (
    <ProceedButtonWrapper>
      {description && <ProceedDescription>{description}</ProceedDescription>}

      <Button
        type={type}
        variant="primary"
        size="large"
        aria-label={text}
        disabled={disabled}
        isLoading={isLoading}
        icon={icon}
      >
        {text}
      </Button>
    </ProceedButtonWrapper>
  )
}
