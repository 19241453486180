import { UseQueryOptions, useMutation } from "@tanstack/react-query"
import toast from "react-hot-toast"

import { certificationProcessService } from "../.."
import { CertificationProcessCreate } from "../types"

export function useCreateCertificationProcess(
  accommodationId: string,
  options?: UseQueryOptions,
) {
  function mutateFn(data: CertificationProcessCreate) {
    return certificationProcessService.create(accommodationId, data)
  }

  return useMutation(mutateFn, {
    onSuccess: (...args) => {
      options?.onSuccess?.(args)
      toast.success("Project aangemaakt")
    },
  })
}
