import { UseQueryOptions, useMutation } from "@tanstack/react-query"
import { implementedSportProductService } from "src/services"
import {
  ImplementedSportProductCreateMany,
  ImplementedSportProductWithDetails,
} from "src/services/v2/implemented-sport-product"

export function useCreateImplementedSportProducts(
  accId: string,
  options?: UseQueryOptions,
) {
  function mutateFn(data: ImplementedSportProductCreateMany) {
    return implementedSportProductService.createMany(accId, data)
  }

  return useMutation(mutateFn, {
    onSuccess: (data: ImplementedSportProductWithDetails[]) => {
      if (options?.onSuccess) {
        options?.onSuccess(data)
      }
    },
  })
}
