import { styled } from "src/stitches.config"

export const FormGroup = styled("div", {
  display: "flex",
  flexDirection: "column",
  padding: "$20 $20 $10",
  marginBottom: "$10",
  borderRadius: "8px",
  backgroundColor: "$grayLight",
})
